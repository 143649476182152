export const ST_COMMENTS_KEY = 'comments'
export const ST_LD_KEY = 'ld_summarizer'
export const ST_VAL_EXT_KEY = 'value_extractor'
export const ST_CHAINER_KEY = 'chainer'

export const SYSTEM_TYPE_COMMENTS = { key: ST_COMMENTS_KEY, value: 'Comments' }
export const SYSTEM_TYPE_LEGAL_DESCRIPTIONS_SUMMARIZER = { key: ST_LD_KEY, value: 'Legal Description Summarizer' }
export const SYSTEM_TYPE_CHAINER = { key: ST_CHAINER_KEY, value: 'Chainer' }
export const SYSTEM_TYPE_DEED_VALUES = { key: ST_VAL_EXT_KEY, value: 'Value Extractor' }

export const SYSTEM_TYPES = [
    
    SYSTEM_TYPE_DEED_VALUES,
    SYSTEM_TYPE_COMMENTS,
    SYSTEM_TYPE_LEGAL_DESCRIPTIONS_SUMMARIZER,
    SYSTEM_TYPE_CHAINER,
    
]