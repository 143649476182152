
export const ROUTE_PATHS = {
    
    // DEBUG
    KITCHEN_SINK: '/kitchensink',
    KITCHEN_SINK_RENDER_PDF: 'renderpdf',
    KITCHEN_SINK_LABEL_STUDIO: 'labelstudio',
    KITCHEN_SINK_TYPOGRAPHY: 'typography',
    KITCHEN_SINK_REALTIME: 'realtime',
    
    SIGNIN: '/signin',
    SIGNUP: '/signup',
    EMAIL_SENT: '/emailsent',
    RESEND_EMAIL: '/resendemail',
    FORGOT_PASSWORD: '/forgotpassword',
    RESET_PASSWORD: '/resetpassword',
    RESET_PASSWORD_EMAIL_SENT: '/resetpasswordemailsent',
    SET_PASSWORD: '/setpassword',
    SET_BILLING: '/setbilling',
    SET_ORGANIZATIONS: '/setorganizations',
    COLLECTIONS: '/collections',
    HALFILE: '/halfile',
    TRACTS: '/tracts',
    RUNSHEETS: '/runsheets',
    TRACT_DETAIL: '/tract/:id',
    RUNSHEET_DETAIL: '/runsheet/:id',
    RUNSHEET_TITLE_BREAKDOWN: '/runsheet/:id/title-breakdown',
    TRACT_OWNERSHIP: '/tract/:id/ownership',
    RUNSHEET_DETAIL_TRACT_ID: '/runsheet/:id/:tractId',
    VIEW_DOCUMENT_ID: '/edit/:documentId',
    PROJECTS: '/projects',
    PROJECT_DETAIL: '/project/:id',
    COLLECTION_DETAIL: '/collection/:collectionId',
    COLLECTION_DETAIL_ACTIVE_TAB: '/collection/:collectionId/:routeActiveTab',
    HALFILE_DETAIL: '/halfile/:countyKey',
    CHECKS: '/checks',
    CHECK_DETAIL: '/checks/:checkId',
    CHECK_DETAIL_EDIT: '/checks/:checkId/edit',
    CHECK_PREVIEW: '/checks/preview/:checkId',
    CHECK_PREVIEW_BY_PAGE: '/checks/preview/:checkId/:pageIndex',
    CHECK_OPERATORS: '/checks/operators',
    CHECK_OPERATOR_DETAIL: '/checks/operators/:operatorName',
    CHECK_WELLS: '/checks/wells',
    CHECK_WELL_DETAIL: '/checks/wells/:wellName',
    CHECK_REPORTS: '/checks/reports',
    CHECK_PORTFOLIO: '/checks/portfolio',
    IAM: '/iam',
    SETTINGS: '/settings',
    EMAIL: ':email',
    ORGS: 'orgs',
    ORG_DETAIL: 'orgs/:orgId',
    ORG_BILLING: 'billing',
    TEMPLATES: 'templates',
    TEMPLATE_DETAIL: 'templates/:templateId',
    HISTORY: 'history',
    BILLING: 'billing',
    PURCHASES: 'purchases',
    PERSONAL_INFO_EMAIL: '/personalInfo/:email',
    PARTNERS: '/partners',
    PARTNERS_DOCUMENTS: '/partners/documents',
    PARTNERS_CHECKS: '/partners/checks',
    PARTNERS_ANNOTATE_DOCUMENT_ID: '/partners/annotate/document/:recordId',
    PARTNERS_ANNOTATE_CHECK_ID: '/partners/annotate/check/:recordId',
}

export const PUBLIC_ROUTE_PATHS = [
    ROUTE_PATHS.SIGNIN,
    ROUTE_PATHS.SIGNUP,
    ROUTE_PATHS.EMAIL_SENT,
    ROUTE_PATHS.RESEND_EMAIL,
    ROUTE_PATHS.FORGOT_PASSWORD,
    ROUTE_PATHS.RESET_PASSWORD,
    ROUTE_PATHS.RESET_PASSWORD_EMAIL_SENT,
    ROUTE_PATHS.SET_PASSWORD,
    ROUTE_PATHS.SET_BILLING,
    ROUTE_PATHS.SET_ORGANIZATIONS,
]

// Publicly accessible route paths
export const UNAUTHENTICATED_PATHS = [
    '/signup',
    '/signin',
    '/setpassword',
    '/setorganizations',
]

// Route paths that should not be auto-redirected to on sign-in
export const SKIP_AUTH_REDIRECT_PATHS = [
    ...UNAUTHENTICATED_PATHS,
    '/setpassword',
    '/resetpassword',
    '/setorganizations',
]
