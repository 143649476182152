import cn from 'classnames'
import { MdOutlineAddBox } from 'react-icons/md'
import { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import * as store from '$store'
import { ST_VAL_EXT_KEY, SYSTEM_TYPES } from '@/constants/exampleManager'
import { useWireValue } from '@forminator/react-wire'

const ExampleManagerTabs = ({ setIncludeSubTabModal }) => {
    
    const [searchParams, setSearchParams] = useSearchParams({ system: ST_VAL_EXT_KEY })
    const systemTemplates = useWireValue(store.systemTemplates)
    
    const system = useMemo(() => searchParams.get('system') || ST_VAL_EXT_KEY, [searchParams.get('system')])
    const templateId = useMemo(() => searchParams.get('templateId'), [searchParams.get('templateId')])
    
    const currentTemplates = useMemo(() =>
        systemTemplates.filter(st => st.system === system),
    [systemTemplates, system])
    
    useEffect(() => {
        
        if (!currentTemplates?.[0]) return
        
        setSearchParams(prev => {
            // if its already a valid template, then i dont need to reset it
            if (currentTemplates.some(ct => ct.id === prev.get('templateId')))
                return prev
            
            prev.set('templateId', currentTemplates[0].id)
            return prev
        })
        
    }, [system, currentTemplates])
    
    return (
        <div className="flex flex-col">
            <div role="tablist" className="tabs tabs-boxed mt-4 shadow-md w-fit justify-start">
                {SYSTEM_TYPES.map(tab =>
                    <a
                        key={tab.key}
                        role="tab"
                        className={cn('tab whitespace-nowrap', { 'tab-active': system === tab.key })}
                        onClick={() => {
                            if (tab.key === system) return
                            
                            setSearchParams(prev => {
                                prev.set('system', tab.key)
                                prev.delete('templateId')
                                return prev
                            })
                        }}>
                        {tab.value}
                    </a>,
                )}
            </div>
            <div className="flex flex-row gap-2 mt-4">
                <div role="tablist"
                    className="tabs tabs-boxed shadow-md gap-1">
                    {setIncludeSubTabModal && <a className="tab cursor-pointer flex items-center
                                    hover:bg-base-100 px-2 tooltip tooltip-right"
                    data-tip="Add a new Template"
                    onClick={() => setIncludeSubTabModal(true)}>
                        <MdOutlineAddBox className="text-xl" />
                    </a>}
                    {currentTemplates.map(tmpl =>
                        <a
                            key={tmpl.id}
                            role="tab"
                            className={cn('tab whitespace-nowrap', {
                                'tab-active': templateId === tmpl.id,
                            })}
                            onClick={() =>
                                setSearchParams(prev => {
                                    prev.set('templateId', tmpl.id)
                                    return prev
                                })}>
                            {tmpl.name}
                        </a>,
                    )}
                </div>
            </div>
        </div>
    )
}

export default ExampleManagerTabs