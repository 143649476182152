import ParseAPI from '@api/ParseAPI'
import * as store from '$store'



export const saveDocumentAsExample = async (payload, selectedSubTab) => {
    
    const res = await ParseAPI.post('exampleManager/', { payload, selectedSubTab })
    
    return res.data
}

export const checkIfAlreadyIsAnExample = async documentId => {
    
    const res = await ParseAPI.get(`exampleManager/isExample/${documentId}`)
    
    return res.data
    
}

export const editDocumentAsExample = async (exampleId, payload, selectedSubTab) => {
    
    const res = await ParseAPI.put(`exampleManager/${exampleId}`, { payload, selectedSubTab })
    
    const examples = store.examples.getValue()
    const idx = examples.findIndex(e => e.id === res.data.id)
    const copy = [...examples]
    
    copy[idx] = { ...copy[idx], ...res.data }
    store.examples.setValue(copy)
    
    return res.data
}

export const getSubTabs = async () => {
    
    const res = await ParseAPI.get('exampleManager/subTabs/')
    
    store.systemTemplates.setValue(res.data)
    // return res.data
    
}

export const editPresetDocument = async (presetDocumentId, payload) => {
    
    const res = await ParseAPI.put(`exampleManager/presets/${presetDocumentId}`, { payload })
    
    const oldPresetDocumentsValues = store.presetDocuments.getValue()
    const idx = oldPresetDocumentsValues.findIndex(e => e.id === res.data.id)
    const copy = [...oldPresetDocumentsValues]
    
    copy[idx] = { ...copy[idx], ...res.data }
    store.presetDocuments.setValue(copy)
    
    return res.data
}