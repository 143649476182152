import CustomModal from '../shared/CustomModal/CustomModal'
import ModalBody from '../shared/CustomModal/ModalBody'
import ModalFooter from '../shared/CustomModal/ModalFooter'
import ModalHeader from '../shared/CustomModal/ModalHeader'
import { useEffect, useMemo, useState } from 'react'
import * as actions from '@actions'
import { SYSTEM_TYPES } from '@/constants/exampleManager'
import { useWireValue } from '@forminator/react-wire'
import * as store from '$store'
import ScrollableDocumentViewer from '../collection/DocumentViewerV3/ScrollableDocumentViewer'
import { buildRecordUrl } from '@/utils'
import { toast } from 'react-toastify'

const ExampleManagerModal = (
    {
        show,
        handleClose,
        example,
        document,
        isPreset = false,
    },
) => {
    
    const subTabs = useWireValue(store.systemTemplates)
    const [exampleData, setExampleData] = useState(example || 
        { documentId: document.id, system: SYSTEM_TYPES[0].key })
    const templatesBySystem = useMemo(() =>
        subTabs.filter(st => st.system === exampleData.system),
    [subTabs, exampleData.system])
    
    const pdfUrl = useMemo(() => buildRecordUrl(document, true, document?.collection?.isParse), [document])
    
    const updateByKey = (key, value) => {
        const copy = { ...exampleData }
        
        copy[key] = value
        
        setExampleData(copy)
        
    }
    
    const upsertExample = async () => {
        
        if (!exampleData.valueExtractorTemplateId) return
        
        if (example) {
            if (isPreset) {
                await actions.editPresetDocument(exampleData.id, exampleData)
                toast.success('Updated preset document sucessfully.')
            } else {
                await actions.editDocumentAsExample(exampleData.id, exampleData)
                toast.success('Updated example sucessfully.')
            }
        } else {
            await actions.saveDocumentAsExample(exampleData)
            toast.success(`Created example from document "${document.name}" sucessfully.`)
        }
        
        handleClose(false)
    }
    
    useEffect(() => {
        actions.getSubTabs()
    },[])
    
    return (
        <CustomModal
            open={show}
            modalHandler={handleClose}
            className="max-w-[95vw]">
            <ModalHeader>
                {example ? 'Edit' : 'Create'} Example
            </ModalHeader>
            <ModalBody>
                <div className="flex gap-4 h-[calc(100vh-200px)]">
                    <div className="flex-1">
                        <ScrollableDocumentViewer url={pdfUrl} />
                    </div>
                    <form className="flex-1 flex flex-col gap-4 overflow-auto">
                        <div className="flex gap-4">
                            <div className="flex flex-col flex-1">
                                <label htmlFor="system">
                                    System
                                </label>
                                <select
                                    id="system"
                                    className="select select-bordered"
                                    value={exampleData.system}
                                    onChange={e => updateByKey('system', e.target.value)}>
                                    {SYSTEM_TYPES.map(it => (
                                        <option key={it.key} value={it.key}>
                                            {it.value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            
                            <div className="flex flex-col flex-1">
                                <label htmlFor="subsystem">
                                    Template
                                </label>
                                <div className="flex flex-col gap-0">
                                    <select
                                        required={true}
                                        id="subsystem"
                                        className="select select-bordered"
                                        defaultValue=""
                                        value={exampleData.valueExtractorTemplateId}
                                        onChange={e => updateByKey('valueExtractorTemplateId', e.target.value)}>
                                        <option disabled value=""></option>
                                        {templatesBySystem.map(it => (
                                            <option key={it.id} value={it.id}>{it.name}</option>
                                        ))}
                                    </select>
                                </div>
                            
                            </div>
                        </div>
                        
                        <div className="flex gap-4">
                            <div className="flex flex-col w-6/12">
                                <label htmlFor="type">
                                    Type
                                </label>
                                <input
                                    type="text"
                                    id="type"
                                    className="input input-bordered"
                                    placeholder="Type"
                                    value={exampleData.type}
                                    onChange={e => updateByKey('type', e.target.value)} />
                            </div>
                            <div className="flex flex-col w-6/12">
                                <label htmlFor="subtype">
                                    Subtype
                                </label>
                                <input
                                    type="text"
                                    id="subtype"
                                    className=" input input-bordered"
                                    placeholder="SubType"
                                    value={exampleData.subType}
                                    onChange={e => updateByKey('subType', e.target.value)} />
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="description">
                                Description
                            </label>
                            <textarea
                                id="description"
                                className="input input-bordered"
                                placeholder="One sentence description/summary"
                                value={exampleData.description}
                                onChange={e => updateByKey('description', e.target.value)} />
                        </div>
                        
                        <div className="flex flex-col">
                            <label htmlFor="relevantText">
                                Relevant Text
                            </label>
                            <textarea
                                className="input input-bordered h-60"
                                id="relevantText"
                                placeholder="Relevant text"
                                value={exampleData.relevantText}
                                onChange={e => updateByKey('relevantText', e.target.value)} />
                        </div>
                        
                        <div className="flex flex-col">
                            <label htmlFor="logic">
                                Logic
                            </label>
                            <textarea
                                id="logic"
                                className="input input-bordered"
                                placeholder="Logic"
                                value={exampleData.logic}
                                onChange={e => updateByKey('logic', e.target.value)} />
                        </div>
                        
                        <div className="flex flex-col">
                            <label htmlFor="answer">
                                Answer
                            </label>
                            <textarea
                                className="input input-bordered h-60"
                                id="answer"
                                placeholder="Answer: None"
                                value={exampleData.answer}
                                onChange={e => updateByKey('answer', e.target.value)} />
                        
                        </div>
                    </form>
                </div>
            </ModalBody>
            <ModalFooter>
                <button
                    className="btn btn-primary btn-outline"
                    onClick={() => handleClose(false)}>
                    Cancel
                </button>
                <button
                    className="btn btn-primary"
                    disabled={!exampleData.valueExtractorTemplateId}
                    onClick={upsertExample}>
                    {example ? 'Update' : 'Create'}
                </button>
            </ModalFooter>
        </CustomModal>
        
    )
}

export default ExampleManagerModal