import CustomModal, { ModalBody, ModalFooter, ModalHeader } from '@/components/shared/CustomModal'
import { useEffect, useState } from 'react'
import { FaTrash } from 'react-icons/fa'
import * as actions from '$actions'
import cn from 'classnames'
import { MdAdd } from 'react-icons/md'
import { toast } from 'react-toastify'

const InstructionAndDefinitionModal = ({
    
    showInstructionDefinitionModal,
    setShowInstructionDefinitionModal,
    activeTab,
    activeSubTab,
    
}) => {
    
    const [instruction, setInstruction] = useState('')
    const [definitions, setDefinitions] = useState([{ key: '', definition: '' }])
    
    const createDefinition = async () => {
        
        const savedDefinitions = await actions.saveInstructionAndDefinitions(
            instruction, definitions, activeTab, activeSubTab)
        
        setDefinitions(savedDefinitions)
        
        toast.success('Successfully updated Instructions and Definitions')
    }
    
    const addNewDefinition = () => {
        setDefinitions([...definitions, { key: '', definition: '' }])
    }
    
    const deleteDefinition = async (definitionId, idx) => {
        
        if (definitionId) {
            
            await actions.deleteDefinition(definitionId)
            setDefinitions(definitions.filter(def => def.id !== definitionId))
            
        } else {
            
            definitions.splice(idx, 1)
            setDefinitions([...definitions])
            
        }
        
    }
    
    useEffect(() => {
        if (!activeSubTab)
            return
        
        actions.findInstructionAndDefinitions(activeSubTab).then(data => {
            setInstruction(data.template.instructions)
            setDefinitions(data.definitions.length ? data.definitions : [{ key: '', definition: '' }])
        })
        
    }, [activeSubTab])
    
    return (
        <CustomModal
            open={showInstructionDefinitionModal}
            modalHandler={() => setShowInstructionDefinitionModal(false)}
            className="max-w-[95vw]">
            <ModalHeader>
            Edit Instructions/Definitions
            </ModalHeader>
            <ModalBody>
                <div className="flex flex-row w-full gap-2 max-h-[calc(100vh-200px)]">
                    <div className={cn('flex flex-col gap-4', {
                        'w-full': activeTab !== 'value_extractor',
                        'flex-1': activeTab === 'value_extractor',
                    })}>
                        <label htmlFor="instruction">Instructions</label>
                        <textarea
                            id="instruction"
                            type="textarea"
                            className="input input-bordered w-full h-[calc(100vh-200px)]"
                            value={instruction}
                            onChange={e => setInstruction(e.target.value)}></textarea>
                    </div>
                    
                    {activeTab === 'value_extractor' && (
                        <div className="flex-1 ">
                            <div className="flex flex-row gap-4 justify-between w-full mb-1">
                                <label htmlFor="definitions"> Definitions </label>
                                <button 
                                    className="btn btn-primary" 
                                    onClick={() => addNewDefinition()}> <MdAdd /> Add Definition </button>
                            </div>
                            
                            <div className="overflow-auto h-[calc(100vh-240px)]">
                                {definitions.map((definition, idx) => {
                                    return (
                                        <div key={definition.id} className="bg-base-100 rounded mb-2 p-4">
                                            <div className="flex justify-around gap-4">
                                                <div className="flex flex-col w-4/12">
                                                    <label htmlFor="vname"> Value Name </label> 
                                                    <input 
                                                        id="vname" 
                                                        type="text" 
                                                        className="input input-bordered w-full" 
                                                        value={definition.key}
                                                        onChange={e => {
                                                            definitions[idx] = {
                                                                ...definition, 
                                                                key: e.target.value,
                                                            }
                                                            const newArr = [...definitions]
                                                            
                                                            setDefinitions(newArr)
                                                        }}/>
                                                </div>
                                                <div className="flex flex-col self-center w-8/12">
                                                    <label htmlFor="def"> Definition </label>
                                                    <textarea 
                                                        id="def" 
                                                        className="input input-bordered h-20 w-full" 
                                                        value={definition.definition}
                                                        onChange={e => {
                                                            definitions[idx] = {
                                                                ...definition,
                                                                definition: e.target.value,
                                                            }
                                                            const newArr = [...definitions]
                                                            
                                                            setDefinitions(newArr)
                                                        }}/>
                                                </div>
                                                <button 
                                                    onClick={() => {
                                                        deleteDefinition(definition.id, idx)
                                                    }}
                                                    className='cursor-pointer text-red-400 
                                                    hover:text-red-600 self-start'>
                                                    <FaTrash />
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </ModalBody>
            <ModalFooter>
                <button 
                    className="btn btn-primary btn-outline" 
                    onClick={() => setShowInstructionDefinitionModal(false)}> 
                    Cancel 
                </button>
                <button 
                    className="btn btn-primary"
                    onClick={() => createDefinition()}>
                    Update
                </button>
            </ModalFooter>
        </CustomModal>
    )
}

export default InstructionAndDefinitionModal