import api from '$lib/api'
import * as store from '$store'

export const deployToLiveTable = async presetId => {
    
    const res = await api.post(`exampleManager/deployToLiveTable/${presetId}`)
    
    return res.data
}

export const loadFromLiveTable = async (activeTab, activeSubTab) => {
    
    const res = await api.post('exampleManager/loadFromLiveTable', { activeTab, activeSubTab })
    
    const oldExamples = store.examples.getValue()
    const examples = oldExamples.filter(ex => !(ex.system === activeTab 
            && ex.valueExtractorTemplateId === activeSubTab))
    
    store.examples.setValue([...examples, ...res.data])
    
}

export const deleteDefinition = async definitionId => {
    
    await api.delete(`exampleManager/definition/${definitionId}`)
    
}

export const deletePresetDefinition = async presetDefinitionId => {
    
    await api.delete(`exampleManager/presetDefinition/${presetDefinitionId}`)
    
}

export const saveInstructionAndDefinitions = async (instruction, definitions, activeTab, activeSubTab) => {
    
    const res = await api.post('exampleManager/saveInstructionAndDefinitions', 
        { instruction, definitions, activeTab, activeSubTab })
    
    return res.data
    
}

export const savePresetInstructionAndDefinitions = async (instruction, definitions, presetId) => {
    
    const res = await api.post(`exampleManager/savePresetInstructionAndDefinitions/${presetId}`, 
        { instruction, definitions })
    
    return res.data
    
    
}

export const findPresetInstructionAndDefinitions = async presetId => {
    
    const res = await api.get(`exampleManager/findPresetInstructionAndDefinitions/${presetId}`)
    
    return res.data
    
}

export const findInstructionAndDefinitions = async activeSubTab => {
    
    const res = await api.post('exampleManager/findInstructionAndDefinitions', { activeSubTab })
    
    return res.data
}

export const copyToWorkingTable = async presetId => {
    
    const res = await api.post(`exampleManager/copyToWorkingTable/${presetId}`)
    
    return res.data
    
}

export const getAllPresetDocuments = async presetToFetch => {
    
    const res = await api.get(`exampleManager/getAllPresetDocuments/${presetToFetch}`, presetToFetch)
    
    store.presetDocuments.setValue(res.data)
    
    return res.data
}

export const getAllPresets = async () => {
    
    const res = await api.get('exampleManager/getAllPresets')
    
    store.presets.setValue(res.data)
    
    return res.data
}

export const createPreset = async payload => {
    
    const res = await api.post('exampleManager/createPreset', payload)
    
    return res.data
}

export const getExamples = async () => {
    
    const res = await api.get('exampleManager/')
    
    store.examples.setValue(res.data)
    
    return res.data
}

export const getPromptConfig = async collectionId => {
    const res = await api.get(`promptConfig/${collectionId}`)
    
    return res.data
}

export const savePromptConfig = async promptConfig => {
    
    const res = await api.put(`promptConfig/${promptConfig.id}`, { ...promptConfig })
    
    return res.data
    
}

export const getSubTabs = async () => {
    
    const res = await api.get('exampleManager/subTabs/')
    
    store.systemTemplates.setValue(res.data)
    
    return res.data
}

export const deleteExamples = async ids => {
    
    await api.post('exampleManager/delete', { ids })
    
    const examples = store.examples.getValue()
    
    store.examples.setValue(examples.filter(ex => !ids.includes(ex.id)))
    
}

export const deletePresets = async ids => {
    
    await api.post('exampleManager/presets/delete', { ids })
    
    const presets = store.presets.getValue()
    
    store.presets.setValue(presets.filter(preset => !ids.includes(preset.id)))
    
}

export const addNewSubTab = async (name, system) => {
    
    const res = await api.post('exampleManager/subTab', { name, system })
    
    const oldSubTabs = store.systemTemplates.getValue()
    
    const newSubTabs = [...oldSubTabs, res.data]
    
    store.systemTemplates.setValue(newSubTabs)
    
}

export const createEval = async (presetId, collectionId, runsheetId) => {
    const res = await api.post('exampleManager/createEval', { presetId, collectionId, runsheetId })
    
    return res.data
}   

export const clonePreset = async (selectedPresetId, clonedPresetName) => {
    
    const res = await api.post(`exampleManager/clonePreset/${selectedPresetId}`, { clonedPresetName })
    
    const oldPresets = store.presets.getValue()
    const newPresets = [ res.data, ...oldPresets ]
    
    store.presets.setValue(newPresets)
    
    return res.data
}